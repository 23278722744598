import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../../configuration/use-configuration';
import { CallToActionButton, CallToActionButtonState } from '../call-to-action-button';
import { WidgetTitle } from '../widget-title';
import { classes } from './header-desktop-compact.st.css';

export const HeaderDesktopCompact: React.FC<{
  callToAction?: CallToActionButtonState;
  className?: string;
}> = ({ className, callToAction, children }) => {
  const t = useTranslate();
  const settings = useConfiguration();
  return (
    <section className={className} aria-label={t('top-section.section-area-label')}>
      {settings.isHeaderTitleEnabled && <WidgetTitle className={classes.title} />}
      <div className={classes.bottomBlock}>
        {children}
        {callToAction && (
          <CallToActionButton className={classes.callToActionButton} callToAction={callToAction} />
        )}
      </div>
    </section>
  );
};
