import React, { ReactNode, useState } from 'react';
import { omit, uniqueId } from 'lodash';
import { isShallowEqual } from '~commons/services/is-shallow-equal';
import { useContextSelector, createContext } from '~commons/use-context-selector';

type ModalFn = (toggle: () => void) => ReactNode;
type ModalState = { [id: string]: { modal: ReactNode; prevFocusOwner: HTMLElement | null } };
type ShowModalType = (modalFn: ModalFn) => void;

export type ModalContextType = {
  showModal: ShowModalType;
  modals: ModalState;
};

export const ModalContext = createContext<ModalContextType>({
  showModal: () => null,
  modals: {},
});

export const ModalProvider: React.FC<{}> = (props) => {
  const [modals, setModals] = useState<ModalState>({});

  const addModal = React.useCallback((id: string, modal: ReactNode) => {
    setModals((m) => ({
      ...m,
      [id]: { modal, prevFocusOwner: document.activeElement as HTMLElement },
    }));
  }, []);

  const toggle = React.useCallback(
    (id: string) => () => {
      setModals((m) => {
        const element = m[id].prevFocusOwner;
        element && element.focus();
        return omit(m, id);
      });
    },
    [],
  );

  const showModal: ShowModalType = React.useCallback(
    (modalFn) => {
      const id = uniqueId();
      addModal(id, modalFn(toggle(id)));
    },
    [addModal, toggle],
  );

  return (
    <ModalContext.Provider
      value={React.useMemo(() => ({ showModal, modals }), [showModal, modals])}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () =>
  useContextSelector(ModalContext, (ctx) => ctx.showModal, isShallowEqual);
