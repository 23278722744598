import React from 'react';
import TextExtended from '../text-extended/text-extended';
import TimeAgo from '../time-ago/time-ago';
import { REVIEW_AUTHOR } from '../../../common/constants/data-hooks';
import { Author } from '../../../controller/lib/reviews-api-types';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { classes, st } from './user-info.st.css';

const UserInfo: React.FC<{
  className?: string;
  author?: Author;
  time?: string | Date;
}> = ({ author, time, className }) => {
  const t = useTranslate();
  return (
    <div className={st(classes.root, className)}>
      <div className={classes.content}>
        {author ? (
          <TextExtended dataHook={REVIEW_AUTHOR} className={classes.username}>
            {author.type === 'UNKNOWN' || !author.name
              ? t('review.unknown-private-member-name')
              : author.name}
          </TextExtended>
        ) : null}
        {author && time ? (
          <TextExtended aria-hidden={true} className={classes.dot}>
            •
          </TextExtended>
        ) : null}
        {time && <TimeAgo className={classes.time} time={time} key="time-ago" />}
      </div>
    </div>
  );
};

export default UserInfo;
