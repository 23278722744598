import React from 'react';
import { ReviewGallery } from '../gallery';
import { classes, st } from './gallery-modal-desktop.st.css';
import VotingButtons from '../../review/voting-buttons';
import { GalleryModalProps } from './index';
import UserInfo from '../../user-info/user-info';
import { RatingsInReview } from '../../ratings-in-reviews/rating-in-reviews';
import TextExtended from '../../text-extended/text-extended';
import CollapsibleText from '../../collapsible-text/collapsible-text';
import { useApi } from '../../api-provider/use-api';
import { getReviewState } from '~reviews/common/store/reviews/reviews/reviews-state-selectors';
import { Dialog } from '../../_common/dialog/dialog';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../../configuration/use-configuration';
import { uniqueId } from 'lodash';
import { MEDIA_MODAL } from '~reviews/common/constants/data-hooks';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { DISABLE_VOTES } from '~/experiments';
import styles from './gallery-modal-desktop.scss';
import classNames from 'classnames';
import { GALLERY_CONSTS } from 'pro-gallery';

export const GalleryModalDesktop: React.FC<GalleryModalProps> = ({
  onClose,
  review: { content, id: reviewId, createdDate, author },
  activeIndex,
}) => {
  // We can not have changing props in Modals because of useModal limitations - lets work around it this one time.
  const { votesState, biClickShowMoreBtn } = useApi((state, actions) => ({
    votesState: getReviewState(reviewId, state.reviews)?.votes,
    biClickShowMoreBtn: actions.biClickShowMoreBtn,
  }));
  const { experiments } = useExperiments();
  const { isReviewDateEnabled, votes: votesSetting } = useConfiguration();
  const votesConfig = experiments.enabled(DISABLE_VOTES) ? 'disabled' : votesSetting;
  const t = useTranslate();
  const descriptionId = React.useMemo(() => uniqueId('helpful'), []);
  const options = {
    layoutParams: {
      structure: {
        galleryLayout: GALLERY_CONSTS.layout.THUMBNAIL,
      },
      navigationArrows: {
        position: 'OUTSIDE_GALLERY' as const,
      },
    },
  };

  return (
    <Dialog
      onClose={onClose}
      childrenWrapperClassName={classes.dialog}
      className={st(classes.root)}
      aria-label={t('review.media-aria-label')}
      data-hook={MEDIA_MODAL}
    >
      <ReviewGallery media={content.media} activeIndex={activeIndex} options={options} />
      <div className={classNames(classes.review, styles.review)}>
        <div className={classes.header}>
          <UserInfo
            className={classes.userInfo}
            author={author}
            time={isReviewDateEnabled ? createdDate : undefined}
          />
        </div>
        <RatingsInReview value={content.rating} isBW />
        <div className={classes.title}>
          <TextExtended fontMapping="reviewTitle" colorMapping="black">
            {content.title}
          </TextExtended>
        </div>
        <CollapsibleText
          clamp="auto"
          className={classes.body}
          textClassName={classes.bodyText}
          onClick={(isShowMore) =>
            biClickShowMoreBtn({ isShowMore, origin: 'media_dialog', reviewId })
          }
          text={content.body ?? ''}
          isBW
        />
        {votesState && votesConfig !== 'disabled' && (
          <div className={classes.footer} role="group" aria-labelledby={descriptionId}>
            <TextExtended
              id={descriptionId}
              fontMapping="content"
              colorMapping="black"
              className={classes.wasThisHelpfulText}
            >
              {t('review.was-this-helpful.question')}
            </TextExtended>
            <VotingButtons
              className={classes.voteButtons}
              votesState={votesState}
              reviewId={reviewId}
              reviewsVoting={votesConfig}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};
