import React from 'react';
import styles from './gallery.scss';
import { wixImageToGallery, wixVideoToGallery } from './media-transform';
import { ProGallery } from 'pro-gallery';
import { Media } from '../../../common/store/reviews/reviews/review-state-types';
import { ChevronLeft, ChevronRight } from 'wix-ui-icons-common';
import { IconButton, IconButtonThemes } from 'wix-ui-tpa/cssVars';
import { debounce, merge } from 'lodash';
import { useConfiguration } from '../configuration/use-configuration';
import { classes as arrowClasses } from './arrows.st.css';

export const ReviewGallery: React.FC<{
  media: Media[];
  activeIndex?: number;
  options?: Object;
  isMobile?: boolean;
}> = ({ media, activeIndex, options: optionsProp, isMobile }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  styles.mustImportThisToHaveAboveCodeWorking;

  const { mockImages } = useConfiguration();
  const [container, setContainer] = React.useState<{ width: number; height: number } | undefined>();
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (containerRef === null) {
      return;
    }
    const setContainerSize = () => {
      const boundingRect = containerRef.getBoundingClientRect();
      setContainer({
        width: Math.floor(boundingRect.width),
        height: Math.floor(boundingRect.height),
      });
    };
    setContainerSize();
    const observer = new ResizeObserver(debounce(setContainerSize, 500));
    observer.observe(containerRef);
    return () => observer.unobserve(containerRef);
  }, [containerRef]);

  const items =
    container &&
    media.map((m) =>
      'image' in m
        ? wixImageToGallery(m.image, container, mockImages)
        : wixVideoToGallery(m.video, container),
    );

  const options = merge(
    {},
    {
      behaviourParams: {
        item: {
          overlay: {
            hoveringBehaviour: 'NEVER_SHOW' as const,
          },
          video: {
            playTrigger: 'CLICK' as const,
            enableControls: true,
            enablePlayButton: true,
          },
        },
      },
      layoutParams: {
        crop: {
          method: 'FIT' as const,
        },
        thumbnails: {
          size: 100,
        },
        navigationArrows: {
          size: 32,
          padding: 8,
        },
      },
    },
    optionsProp,
  );

  // The eventsListener will notify you anytime something has happened in the gallery.
  const eventsListener = (eventName: any, eventData: any) => console.log({ eventName, eventData });

  // The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
  const scrollingElement = window;

  return (
    <div className={styles.container} ref={setContainerRef}>
      {container && items ? (
        <ProGallery
          id="wix-reviews"
          items={items}
          options={options}
          container={container}
          eventsListener={eventsListener}
          scrollingElement={scrollingElement}
          activeIndex={activeIndex}
          totalItemsCount={items?.length ?? 0}
          customComponents={{
            customNavArrowsRenderer: (direction: string) => {
              const iconProps = isMobile ? {} : { size: 36 };
              const Icon = direction === 'left' ? ChevronLeft : ChevronRight;
              return (
                <IconButton
                  icon={<Icon {...iconProps} />}
                  theme={IconButtonThemes.Box}
                  className={arrowClasses.root}
                />
              );
            },
          }}
        />
      ) : null}
    </div>
  );
};
