import React from 'react';
import { st, classes } from './text-extended.st.css';

type FontMapping =
  | 'content'
  | 'siteContent'
  | 'siteTitle'
  | 'button'
  | 'reviewTitle'
  | 'headerControl'
  | 'custom';

type ColorMaping = 'content' | 'black' | 'reviewTitle' | 'headerControl' | 'custom';

export interface TextExtendedProps {
  className?: string;
  fontMapping?: FontMapping;
  colorMapping?: ColorMaping;
  dataHook?: string;
  tag?: string;
  size?: number;
  style?: React.CSSProperties;
  id?: string;
  ['aria-hidden']?: boolean;
}

export const TextExtended: React.FC<TextExtendedProps> = ({
  className,
  fontMapping = 'content',
  colorMapping = 'content',
  dataHook,
  size,
  tag,
  ...props
}) => {
  const Component = (tag ?? 'span') as keyof JSX.IntrinsicElements;
  return (
    <Component
      className={st(classes.root, { colorMapping, fontMapping }, className)}
      style={size ? { fontSize: `${size}px` } : undefined}
      data-hook={dataHook}
      {...props}
    />
  );
};
export default TextExtended;
