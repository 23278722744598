import React from 'react';
import { TextArea } from 'wix-ui-tpa/cssVars';
import { st, classes } from './text-area-extended.st.css';

export const TextAreaExtended: React.FC<{
  label?: string;
  ariaLabel?: string;
  value: string;
  onChange: (val: string) => void;
  className?: string;
  maxLength?: number;
  showCharCount?: boolean;
  error?: boolean;
  errorMessage?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  dataHook?: string;
  withResize?: boolean;
}> = ({
  label,
  ariaLabel,
  value,
  onChange,
  className,
  maxLength,
  showCharCount,
  error,
  errorMessage,
  autoFocus,
  disabled,
  placeholder,
  id,
  dataHook,
  withResize,
}) => {
  return (
    <TextArea
      id={id}
      label={label}
      ariaLabel={ariaLabel || label || ''}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      className={st(classes.root, className)}
      maxLength={maxLength}
      showCharCount={showCharCount}
      error={error}
      errorDescription={errorMessage}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      data-hook={dataHook}
      newErrorMessage
      withResize={withResize}
    />
  );
};
