import React from 'react';
import { useErrorMonitor } from '@wix/yoshi-flow-editor';

const Noop = () => null;

export const withErrorBoundary = <T extends {}>(
  Component: React.ComponentType<T>,
  Fallback: React.ComponentType = Noop,
): React.ComponentType<T> => {
  return (props) => (
    <ErrorBoundary
      Fallback={Fallback}
      onError={(error) => useErrorMonitor().captureException(error)}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
};

export class ErrorBoundary extends React.Component<{
  onError: (error: Error, info: React.ErrorInfo) => void;
  Fallback: React.ComponentType;
}> {
  state = { hasError: false };
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError(error, info);
  }

  render() {
    const { Fallback } = this.props;
    if (this.state.hasError) {
      return <Fallback />;
    }

    return this.props.children;
  }
}
