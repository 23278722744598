import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { TextButton, TextButtonPriority as TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/cssVars';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/arrow-icon';
import { st, classes } from './collapsible-text.st.css';
import { SHOW_MORE_BTN } from '~reviews/common/constants/data-hooks';
import { FormattedText } from '../_common/formatted-text/formatted-text';

const LINE_HEIGHT = '1.4em';
const BUTTON_HEIGHT = 30;
const BUTTON_MARGIN = 8;

type CollapsibleTextProps = {
  className?: string;
  textClassName?: string;
  clamp: number | 'auto';
  onClick?: (isShowMore: boolean) => void;
  text: string;
  dataHook?: string;
  isBW?: boolean;
};

const CollapsibleText: React.FC<CollapsibleTextProps> = ({
  className,
  textClassName,
  clamp: clampProp,
  onClick,
  text,
  dataHook,
  isBW = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [bodyRef, setBodyRef] = React.useState<Element | null>();
  const [textRef, setTextRef] = React.useState<Element>();
  const [expandedHeight, setExpandedHeight] = React.useState<number>();
  const [collapseDetails, setCollapseDetails] = React.useState<{
    collapsedHeight: number;
    shouldShowButton: boolean;
    clamp: number;
  }>();
  const onBodyRef = React.useCallback(setBodyRef, [setBodyRef]);
  const onRef = React.useCallback(
    (ref: HTMLDivElement) => {
      if (ref) {
        setTextRef(ref);
        const { clientHeight, scrollHeight } = ref;
        if (clampProp !== 'auto') {
          setCollapseDetails({
            clamp: clampProp ?? 0,
            // scrollHeight is rounded up sometimes therefore we add a 1px threshold
            shouldShowButton: scrollHeight - clientHeight > 1,
            collapsedHeight: clientHeight,
          });
        } else if (bodyRef && clientHeight > bodyRef.clientHeight) {
          const lineHeight = parseFloat(window.getComputedStyle(ref).lineHeight);
          const availableHeight = bodyRef.clientHeight - BUTTON_HEIGHT - BUTTON_MARGIN;
          setCollapseDetails({
            clamp: Math.floor(clientHeight / lineHeight),
            shouldShowButton: true,
            collapsedHeight: Math.floor(availableHeight - (availableHeight % lineHeight)),
          });
        }
      }
    },
    [clampProp, bodyRef],
  );
  const { collapsedHeight, shouldShowButton = false, clamp = clampProp } = collapseDetails ?? {};
  const t = useTranslate();
  React.useEffect(() => {
    if (!expandedHeight && textRef && isExpanded) {
      setExpandedHeight(textRef?.scrollHeight);
    }
  }, [expandedHeight, textRef, isExpanded]);
  const maxHeight = clampProp === 'auto' ? '' : `calc(${clamp} * ${LINE_HEIGHT})`;
  return (
    <div
      className={st(
        classes.root,
        {
          isCollapsed: shouldShowButton && !isExpanded,
          isExpanded: shouldShowButton && isExpanded,
          isBW,
        },
        className,
      )}
      ref={onBodyRef}
    >
      <FormattedText
        className={st(classes.body, textClassName)}
        text={text}
        dataHook={dataHook}
        style={{
          lineHeight: LINE_HEIGHT,
          maxHeight: isExpanded ? expandedHeight || collapsedHeight : collapsedHeight || maxHeight,
        }}
        ref={onRef}
        isBw={isBW}
      />
      {shouldShowButton ? (
        <div className={classes.buttonWrapper} style={{ marginTop: BUTTON_MARGIN }}>
          <TextButton
            style={{ height: BUTTON_HEIGHT }}
            className={classes.button}
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={() => {
              onClick && onClick(!isExpanded);
              setIsExpanded(!isExpanded);
            }}
            suffixIcon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            data-hook={SHOW_MORE_BTN}
          >
            {t(isExpanded ? 'review.show-less' : 'review.show-more')}
          </TextButton>
        </div>
      ) : null}
    </div>
  );
};

export default CollapsibleText;
