import classNames from 'classnames';
import React, { useState, useRef, useLayoutEffect } from 'react';
import styles from './vertically-positioned-container.scss';

const TOP = 'top';
const BOTTOM = 'bottom';

export const VerticallyPositionedContainer: React.FC<{
  className?: string;
  dataHook?: string;
  offset: number;
  centerHorizontally?: boolean;
  centerToScreen?: boolean;
}> = ({ children, className, dataHook, centerHorizontally, centerToScreen, offset = 0 }) => {
  const [position, setPosition] = useState<'top' | 'bottom'>();
  const [containerRect, setContainerRect] = useState<DOMRect>();
  const containerRef = useRef<HTMLDivElement>(null);
  const clientWidth = document.documentElement.clientWidth;

  useLayoutEffect(() => {
    if (!containerRef.current || typeof document === 'undefined') {
      return;
    }
    const containerRect = containerRef.current.getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight;
    const containerHeight = Math.round(containerRect.height);
    const position = clientHeight < containerRect.top + containerHeight + offset ? TOP : BOTTOM;
    setPosition(position);
    setContainerRect(containerRect);
  }, []);

  const isTop = () => position === TOP;

  const containerStyle = {
    [isTop() ? 'bottom' : 'top']: 0,
    [isTop() ? 'marginBottom' : 'marginTop']: `${offset}px`,
  };

  if (centerHorizontally) {
    containerStyle.left = containerRect?.width ? `-${containerRect.width / 2}px` : 'auto';
  }

  if (centerToScreen && containerRect) {
    const rightCorner = containerRect.width + containerRect.x;
    const adjust = rightCorner - clientWidth;
    containerStyle.left =
      clientWidth < rightCorner ? `-${adjust + (containerRect.x - adjust) / 2}px` : 'auto';
  }

  return (
    <div
      className={classNames(styles.container, className)}
      ref={containerRef}
      style={containerStyle}
      data-hook={dataHook}
    >
      {children}
    </div>
  );
};
