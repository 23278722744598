import React from 'react';
import classNames from 'classnames';
import { st, classes } from './list-item-container.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ListItemContainer: React.FC<{
  id: string;
  isReply: boolean;
  dataHook: string;
  isPending?: boolean;
  className?: string;
  isMarked?: boolean;
}> = ({ id, isMarked, isReply, dataHook, isPending = false, children, className }) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      tabIndex={-1}
      id={id}
      data-hook={dataHook}
      className={st(
        classes.root,
        { isMobile, isPending, isReply, isMarked: !!isMarked },
        classNames(className, { 'top-level-comment': !isReply, reply: isReply }),
      )}
    >
      {children}
    </div>
  );
};

export default ListItemContainer;
