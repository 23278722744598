import React from 'react';
import TextExtended from '../text-extended/text-extended';
import { ReplyIcon } from '../icons/reply-icon';
import { REPLY_BTN } from '../../../common/constants/data-hooks';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import VotingButtons from './voting-buttons';
import { VotesState } from '../../../common/store/reviews/votes/review-vote-types';
import { useConfiguration } from '../configuration/use-configuration';
import { uniqueId } from 'lodash';
import { classes, st } from './review-footer.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { DISABLE_VOTES } from '~/experiments';

export type ReviewFooterProps = {
  showReplyButton: boolean;
  onReply?: () => void;
  votesState: VotesState;
  reviewId: string;
};

const ReviewFooter = ({ showReplyButton, votesState, onReply, reviewId }: ReviewFooterProps) => {
  const t = useTranslate();
  const { experiments } = useExperiments();
  const settings = useConfiguration();
  const votes = experiments.enabled(DISABLE_VOTES) ? 'disabled' : settings.votes;

  const { isMobile } = useEnvironment();
  const descriptionId = React.useMemo(() => uniqueId('helpful'), []);
  const shouldWrapButtons = votes === 'full' || (showReplyButton && votes === 'upvotesOnly');

  return (
    <div
      className={st(classes.root, { isMobile, wrapButtons: shouldWrapButtons })}
      role="group"
      aria-labelledby={descriptionId}
    >
      {votes !== 'disabled' && (
        <TextExtended id={descriptionId} className={classes.wasThisHelpfulText}>
          {t('review.was-this-helpful.question')}
        </TextExtended>
      )}
      <div className={classes.buttonsContainer}>
        {votes !== 'disabled' && (
          <VotingButtons
            className={classes.votingButtons}
            votesState={votesState}
            reviewId={reviewId}
            reviewsVoting={votes}
          />
        )}
        {showReplyButton && (
          <button
            data-hook={REPLY_BTN}
            className={st(classes.action, {
              defaultCursor: !onReply,
              isMobile,
            })}
            onClick={onReply}
            disabled={!onReply}
          >
            <ReplyIcon />
            <TextExtended className={classes.actionTitle}>{t('review.reply')}</TextExtended>
          </button>
        )}
      </div>
    </div>
  );
};

export default ReviewFooter;
