import React from 'react';
import { FloatingDropdown } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { style, classes } from './reviews-list-header-desktop.st.css';
import { classes as dropdownClasses } from './floating-dropdown.st.css';
import {
  FILTER_SELECTOR,
  HEADER_TITLE,
  SORTING_SELECTOR,
} from '../../../common/constants/data-hooks';
import { useConfiguration } from '../configuration/use-configuration';
import TextExtended from '../text-extended/text-extended';
import { useRootRef } from '../root-ref-provider/use-root-ref';
import { ReviewsListHeaderProps } from '.';
import {
  getFilterOptions,
  getOrderingOptions,
  parseRatingFilterValue,
  stringifyRatingFilterValue,
} from '~reviews/common/services/ordering-and-filter-utils';
import { Ordering } from '~reviews/controller/lib/reviews-api-types';
import { useApi } from '../api-provider/use-api';

const ReviewsListHeaderDesktop: React.FC<ReviewsListHeaderProps> = React.memo(
  ({ selectedFilter, selectedOrdering: selectedSort, reviewCount, className }) => {
    const t = useTranslate();
    const rootRef = useRootRef();
    const settings = useConfiguration();

    const {
      changeOrdering,
      changeRatingFilter,
      biOpenSorting,
      biOpenFiltering,
      biChangeSorting,
      biChangeFiltering,
    } = useApi((state, actions) => ({
      changeOrdering: actions.changeOrdering,
      changeRatingFilter: actions.changeRatingFilter,
      biOpenSorting: actions.biOpenSorting,
      biOpenFiltering: actions.biOpenFiltering,
      biChangeSorting: actions.biChangeSorting,
      biChangeFiltering: actions.biChangeFiltering,
    }));

    const onOptionExpand = React.useCallback(
      (option: 'sort' | 'filter') => (option === 'filter' ? biOpenFiltering() : biOpenSorting()),
      [biOpenSorting, biOpenFiltering],
    );

    const filterOptions = React.useMemo(() => {
      return getFilterOptions(t);
    }, [t]);

    const orderingOptions = React.useMemo(() => {
      return getOrderingOptions(t, selectedFilter);
    }, [selectedFilter, t]);

    const onSortChange = React.useCallback(
      (ordering: string) => {
        biChangeSorting({ ordering: ordering as Ordering });
        changeOrdering({
          ordering: ordering as Ordering,
        });
      },
      [changeOrdering, biChangeSorting],
    );

    const onRatingFilterChange = React.useCallback(
      (rating: string) => {
        biChangeFiltering({ rating, origin: 'dropdown' });
        changeRatingFilter({
          ratingFilter: parseRatingFilterValue(rating),
        });
      },
      [changeRatingFilter, biChangeFiltering],
    );

    return (
      <section
        aria-label={t('filters-row.section-area-label')}
        className={style(classes.root, className)}
      >
        <div data-hook={HEADER_TITLE} className={classes.start}>
          <TextExtended fontMapping="headerControl" colorMapping="headerControl">
            {t('filters-row.all-reviews', { count: reviewCount })}
          </TextExtended>
        </div>
        <div className={classes.end}>
          {settings.isFilterEnabled && rootRef && (
            <FloatingDropdown
              value={stringifyRatingFilterValue(selectedFilter)}
              data-hook={FILTER_SELECTOR}
              onChange={(option) => onRatingFilterChange(option.id!)}
              onExpandedChange={(isOpened) => isOpened && onOptionExpand('filter')}
              options={filterOptions}
              label={t('filters-row.filter-by')}
              className={dropdownClasses.root}
              appendTo={rootRef}
              removeElementMinWidth
              dynamicWidth
            />
          )}
          {settings.isSortEnabled && rootRef && (
            <FloatingDropdown
              value={selectedSort}
              data-hook={SORTING_SELECTOR}
              onChange={(option) => onSortChange(option.id!)}
              onExpandedChange={(isOpened) => isOpened && onOptionExpand('sort')}
              options={orderingOptions}
              label={t('filters-row.sort-by')}
              className={dropdownClasses.root}
              appendTo={rootRef}
              removeElementMinWidth
              dynamicWidth
            />
          )}
        </div>
      </section>
    );
  },
);

ReviewsListHeaderDesktop.displayName = 'ReviewsListHeaderDesktop';

export default ReviewsListHeaderDesktop;
