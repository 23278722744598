import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../../configuration/use-configuration';
import { CallToActionButton } from '../call-to-action-button';
import { HeaderRatingFull } from '../header-rating-full';
import { RatingsBreakdown } from '../ratings-breakdown';
import { WidgetTitle } from '../widget-title';
import { classes, st } from './header-desktop-full.st.css';
import { HeaderPropType } from './index';

export const HeaderDesktopFull: React.FC<HeaderPropType> = ({ callToAction, reviewsSummary }) => {
  const t = useTranslate();
  const settings = useConfiguration();
  return (
    <section
      aria-label={t('top-section.section-area-label')}
      className={st(classes.root, { compact: !settings.isReviewBarchartEnabled })}
    >
      <div className={classes.firstBlock}>
        {settings.isHeaderTitleEnabled && <WidgetTitle className={classes.title} />}
        <HeaderRatingFull
          className={classes.rating}
          value={reviewsSummary.overall}
          countDisplay={t('top-section.total-rating', {
            count: reviewsSummary.totalReviews,
          })}
          ratingDisplay={reviewsSummary.overall.toFixed(1)}
        />
        {callToAction && (
          <CallToActionButton className={classes.callToActionButton} callToAction={callToAction} />
        )}
      </div>
      <div className={classes.secondBlock}>
        <RatingsBreakdown
          breakdown={reviewsSummary.ratingsBreakdown}
          totalReviews={reviewsSummary.totalReviews}
          clickable={settings.isFilterEnabled}
        />
      </div>
    </section>
  );
};
