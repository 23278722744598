import React from 'react';
import { ROOT } from '../../../common/constants/data-hooks';
import { RootRefProvider } from '../root-ref-provider/use-root-ref';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { classes, st } from './app-wrapper.st.css';
import '../../styles/global-styles.scss';
import styles from '../../styles/review-styles.scss';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { makeRootId } from '~reviews/common/services/id-utils';
import { useUnsafeApi } from '../api-provider/use-api';

const AppWrapper: React.FC = ({ children }) => {
  const { isRTL, isMobile } = useEnvironment();
  const t = useTranslate();
  const { resourceId, namespace } =
    // App wrapper is rendered outside of apiContextGuard, so it won't have api
    // when in the fallback branch
    useUnsafeApi((s) => {
      if (s.reviews.type === 'INITIAL') {
        return { resourceId: undefined, namespace: undefined };
      }
      return { resourceId: s.reviews.config.resourceId, namespace: s.reviews.config.namespace };
    }) ?? {};

  const [ref, setRootRef] = React.useState<HTMLElement | null>(null);
  return (
    <section
      className={st(
        classes.root,
        { isDesktop: !isMobile },
        styles.reviews,
        'wr-comments-root',
        isMobile ? 'app-mobile' : 'app-desktop',
      )}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook={ROOT}
      ref={setRootRef}
      aria-label={t('top-section.title')}
      id={namespace && resourceId && makeRootId(namespace, resourceId)}
      tabIndex={-1}
    >
      <div className={isMobile ? 'wr-app-mobile' : 'wr-app-desktop'}>
        <RootRefProvider rootRef={ref}>{children}</RootRefProvider>
      </div>
    </section>
  );
};

export default AppWrapper;
