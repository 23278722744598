import { SmallStars } from '@wix/ftsystem';
import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { st, classes } from './rating-in-reviews.st.css';

export const RatingsInReview: React.FC<{ isBW?: boolean; value: number; dataHook?: string }> = ({
  isBW = false,
  value,
  dataHook,
}) => {
  const t = useTranslate();
  return (
    <SmallStars
      dataHook={dataHook}
      a11yLabel={t('review.rating-aria-label', { rating: value })}
      rating={value}
      className={st(classes.root, { isBW })}
    />
  );
};
