import {
  StyleParamType,
  createStylesParams,
  wixColorParam,
  wixFontParam,
  ISettingsColor,
} from '@wix/tpa-settings';
import parseColor from 'color';

export type AllStylesParams = {
  // -------Header styles-------//
  headerActiveStarColor: StyleParamType.Color;
  headerInctiveStarColor: StyleParamType.Color;
  progressBarColor: StyleParamType.Color;
  progressBarBgColor: StyleParamType.Color;
  progressBarCornerRadius: StyleParamType.Number;
  starBarchartTextFont: StyleParamType.Font;
  starBarchartTextFontSize: StyleParamType.Number;
  starBarchartTextColor: StyleParamType.Color;
  headerTitleTextFont: StyleParamType.Font;
  headerTitleTextFontSize: StyleParamType.Number;
  headerTitleTextColor: StyleParamType.Color;
  headerScoreTextFont: StyleParamType.Font;
  headerScoreTextColor: StyleParamType.Color;
  headerButtonBgColor: StyleParamType.Color;
  headerButtonBorderColor: StyleParamType.Color;
  headerButtonBorderWidth: StyleParamType.Number;
  headerButtonCornerRadius: StyleParamType.Number;
  headerButtonTextFont: StyleParamType.Font;
  headerButtonTextColor: StyleParamType.Color;
  headerButtonHoverBgColor: StyleParamType.Color;
  headerButtonHoverBorderColor: StyleParamType.Color;
  headerButtonHoverTextColor: StyleParamType.Color;
  // -------Review list styles-------//
  reviewActiveStarColor: StyleParamType.Color;
  reviewInactiveStarColor: StyleParamType.Color;
  reviewTitleTextColor: StyleParamType.Color;
  reviewTitleTextFont: StyleParamType.Font;
  reviewBodyTextColor: StyleParamType.Color;
  reviewBodyTextFont: StyleParamType.Font;
  sortAndFilterTextFont: StyleParamType.Font;
  sortAndFilterTextFontSize: StyleParamType.Number;
  sortAndFilterTextColor: StyleParamType.Color;
  helpfulVotesTextColor: StyleParamType.Color;
  helpfulVotesTextFont: StyleParamType.Font;
  activeHelpfulVoteColor: StyleParamType.Color;
  reviewSeparatorColor: StyleParamType.Color;
  linkButtonTextFont: StyleParamType.Font;
  linkButtonTextColor: StyleParamType.Color;
  cardsSpacing: StyleParamType.Number;
  reviewHorizontalPadding: StyleParamType.Number;
  reviewCardVerticalPadding: StyleParamType.Number;
  reviewListVerticalPadding: StyleParamType.Number;
  reviewCardCornerRadius: StyleParamType.Number;
  reviewCardBorderColor: StyleParamType.Color;
  reviewCardColor: StyleParamType.Color;
  reviewCardShadowColor: StyleParamType.Color;
  reviewShadowBlur: StyleParamType.Number;
  reviewShadowDistance: StyleParamType.Number;
  reviewShadowDirection: StyleParamType.Number;
  reviewShadowX: StyleParamType.Number;
  reviewShadowY: StyleParamType.Number;
  // -------Review form styles-------//
  formStarColor: StyleParamType.Color;
  formInputBgColor: StyleParamType.Color;
  formInputBorderColor: StyleParamType.Color;
  formInputCornerRadius: StyleParamType.Number;
  formInputBorderWidth: StyleParamType.Number;
  formCornerRadius: StyleParamType.Number;
  formBorderColor: StyleParamType.Color;
  formBorderWidth: StyleParamType.Number;
  formBgColor: StyleParamType.Color;
  formInputTextFont: StyleParamType.Font;
  formInputTextFontSize: StyleParamType.Number;
  formInputTextColor: StyleParamType.Color;
  formLabelTextFont: StyleParamType.Font;
  formLabelTextColor: StyleParamType.Color;
  formButtonBgColor: StyleParamType.Color;
  formButtonTextFont: StyleParamType.Font;
  formButtonTextColor: StyleParamType.Color;
  formButtonCornerRadius: StyleParamType.Number;
  formSidePadding: StyleParamType.Number;
  // -------Widget styles-------//
  widgetBgColor: StyleParamType.Color;
  widgetBorderColor: StyleParamType.Color;
  widgetBorderWidth: StyleParamType.Number;
  widgetCornerRadius: StyleParamType.Number;
  widgetSidePaddings: StyleParamType.Number;
  widgetVerticalPaddings: StyleParamType.Number;
};

export type StylesParams = typeof styleParams;

const styleParams = createStylesParams<AllStylesParams>({
  // -------Header styles-------//
  headerActiveStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  headerInctiveStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  progressBarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  progressBarBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  progressBarCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  headerTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32 }),
  },
  // for mobile
  headerTitleTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const headerTitleTextFont = props.getStyleParamValue(styleParams.headerTitleTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return headerTitleTextFont.size;
    },
  },
  headerTitleTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  headerScoreTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: (props) =>
      wixFontParam('Page-title', { size: props.isMobile ? 20 : 28 })(props),
  },
  headerScoreTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  starBarchartTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 12 }),
  },
  // for mobile
  starBarchartTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const starBarchartTextFont = props.getStyleParamValue(styleParams.starBarchartTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return starBarchartTextFont.size;
    },
  },
  starBarchartTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  headerButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  headerButtonTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  headerButtonBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  headerButtonCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  cardsSpacing: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewHorizontalPadding: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewCardVerticalPadding: { type: StyleParamType.Number, getDefaultValue: () => 20 },
  reviewListVerticalPadding: { type: StyleParamType.Number, getDefaultValue: () => 24 },
  reviewCardCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 8 },
  headerButtonHoverBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBgColor = props.getStyleParamValue(styleParams.headerButtonBgColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(
        headerButtonBgColor ??
          // Sometimes 'getStyleParamValue' returns undefined :(
          wixColorParam('color-1')(props),
        0.7,
      );
    },
  },
  headerButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBorderColor = props.getStyleParamValue(
        styleParams.headerButtonBorderColor,
        {
          isMobile: props.isMobile,
          isRTL: props.isRTL,
        },
      );
      return applyOpacity(headerButtonBorderColor ?? wixColorParam('color-8')(props), 0.7);
    },
  },
  headerButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonTextColor = props.getStyleParamValue(styleParams.headerButtonTextColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(headerButtonTextColor ?? wixColorParam('color-8')(props), 0.7);
    },
  },
  // -------Review list styles-------//
  reviewActiveStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewInactiveStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  reviewTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  reviewTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  },
  reviewBodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  reviewBodyTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  sortAndFilterTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  sortAndFilterTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const sortAndFilterTextFont = props.getStyleParamValue(styleParams.sortAndFilterTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return sortAndFilterTextFont.size;
    },
  },
  sortAndFilterTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  helpfulVotesTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  helpfulVotesTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  activeHelpfulVoteColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewSeparatorColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  linkButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  linkButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  reviewCardShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  reviewCardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  reviewCardColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  reviewShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 8;
    },
  },
  reviewShadowX: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  reviewShadowY: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 4;
    },
  },
  reviewShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 4;
    },
  },
  reviewShadowDirection: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 180;
    },
  },
  // -------Review form styles-------//
  formStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  formInputBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formInputBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  formInputCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    // only changed in desktop, keep this always to zero in mobile
    inheritDesktop: false,
  },
  formButtonCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    // mobile should not follow desktop
    inheritDesktop: false,
  },
  formBgColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  formBorderColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5', 0.3) },
  formInputBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formInputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  // mobile
  formInputTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const formInputTextFont = props.getStyleParamValue(styleParams.formInputTextFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return formInputTextFont.size;
    },
  },
  formInputTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formLabelTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  formLabelTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formButtonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  formButtonTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  // -------Widget styles-------//
  widgetBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  widgetCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  widgetSidePaddings: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  widgetVerticalPaddings: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
});

const applyOpacity = (c: ISettingsColor, o: number): ISettingsColor => {
  const color = parseColor(c.value);
  const originalOpacity = color.alpha();
  const newOpacity = originalOpacity * o;
  return {
    value: color.alpha(newOpacity).toString(),
    opacity: newOpacity,
    name: null,
  };
};
export default styleParams;
